<template>
  <app-panel type="panel" ref="mapPanel" event-name="openMapPanel" class="map-panel" :title="$t('map_merchant')" @open="open" hide-validate-button>
    <div class="map" ref="gmap">
      <div class="loader">
        <ui-progress-circular color="black"></ui-progress-circular>
      </div>
    </div>
    <div class="merchant-address-info-container">
      <div class="merchant-address-info">
        <div class="merchant">
          <img :src="merchant.logo.path">
          <div class="info">
            <strong>{{merchant.name}}</strong>
            <small>{{merchant.subtitle}}</small>
          </div>
        </div>
        <div class="address">
          <span>{{merchant.address.address}}</span>
          <span>{{merchant.address.address2}}</span>
          <span>{{merchant.address.city}} {{merchant.address.zip}}</span>
        </div>
      </div>
    </div>
  </app-panel>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import PanelTemplate from "@/mobileComponents/PanelTemplate";

export default {
  components: {
    'app-panel': PanelTemplate
  },
  data(){
    return {
      map: {
        $map: null
      },
    }
  },
  methods: {
    loadMap(){
      if(this.map.$map){
        return false;
      }
      this.map.$map = new google.maps.Map(this.$refs.gmap, {
        zoom: 16,
        center: { lat: this.merchant.address.position.lat, lng: this.merchant.address.position.lng },
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        mapTypeId: 'roadmap',
      });
      this.map.$merchant = new google.maps.Marker({position: { lat: this.merchant.address.position.lat, lng: this.merchant.address.position.lng }, map: this.map.$map});
    },
    open(){
      this.loadMap();
    }
  },
  mounted() {

  },
  computed: {
    ...mapGetters(['merchant'])
  }
}
</script>
