<template>
  <section class="product-section" :id="'category' + data.id" v-show="showSection" v-if="productCount > 0">
    <div class="section-title">
      <span>{{data.title}}</span>
      <p v-if="data.description">{{data.description}}</p>
      <div class="availability" v-if="data.availability">
        <i class="material-icons notranslate">info_outline</i>
        <small v-if="data.availability">
          {{ data.availability.text }}
        </small>
      </div>
    </div>
    <div class="product-item" :class="{'product-item-disabled': !product.avaible}" v-for="product in data.products" @click="openProduct(product)"
         v-if="cart.type === 'on_site' && product.onsite === true || cart.type === 'pickup' && product.pickup === true || cart.type === 'on_site_counter' && product.onsite === true || cart.type === 'delivery' && product.delivery === true">
      <div class="infos">
        <strong class="name">{{ product.name }}</strong>
        <p>{{ product.descriptionShort }}</p>
        <div class="priceAndStarMobile">
          <span v-if="cart.type === 'on_site' || cart.type === 'on_site_counter'">{{ product.priceOnsite.price }} <small v-if="!product.avaible">Indisponible</small></span>
          <span v-if="cart.type === 'pickup'">{{ product.pricePickup.price }} <small v-if="!product.avaible">Indisponible</small></span>
          <span v-if="cart.type === 'delivery'">{{ product.priceDelivery.price }} <small v-if="!product.avaible">Indisponible</small></span>
        </div>
      </div>
      <div v-if="product.imageSmall.path" style="position: relative;">
        <img :src="product.imageSmall.path">
        <div style="position: absolute; top:0; padding: 5px 0 0 5px;">
          <i v-if="product.highlighted && product.avaible" class="material-icons starMobile">star</i>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.priceAndStarMobile
{
  display: flex;
  align-items: center;
  margin-top: 5px;
  small{
    margin-left: 20px;
  }
}
.starMobile
{
  background-color: #ffc400;
  border-radius: 8px;
  color: white;
  padding: 3px!important;
  font-size: 1.1em!important;
  font-weight: 600;
}
</style>

<script>
import { mapGetters } from 'vuex';
import {$vfm} from "vue-final-modal";
import AddProduct from "@/components/Modals/Product/AddProduct";

export default {
  props: ['category'],
  data(){
    return {
      data: false
    }
  },
  methods: {
    openProduct(product){
      if(this.merchant.activated === false){
        this.$root.$emit('openOrderTime', {initiated: false});
        return;
      }
      $vfm.show({component: AddProduct, bind: {product: JSON.parse(JSON.stringify(product))}});
      window.history.pushState({}, "",location.pathname + '#product');
    }
  },
  computed: {
    ...mapGetters(['cart', 'merchant']),
    showSection(){
      if(!this.data && this.data.availability && this.data.availability.available === false && this.cart.type === 'on_site_counter'){
        return false;
      }
      return true;
    },
    productCount(){
      if(!this.data){
        return 0;
      }
      if(this.cart.type === 'delivery'){
        return this.data.products.filter(p => p.delivery === true).length;
      }
      else if(this.cart.type === 'pickup'){
        return this.data.products.filter(p => p.pickup === true).length;
      }
      else if(this.cart.type === 'on_site'){
        return this.data.products.filter(p => p.onsite === true).length;
      }
      else if(this.cart.type === 'on_site_counter'){
        return this.data.products.filter(p => p.onsite === true).length;
      }
    }
  },
  created() {
    this.data = JSON.parse(JSON.stringify(this.category));
  }
}
</script>
