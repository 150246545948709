<template>
  <app-panel type="panel" event-name="openDeliveryInfo" :title="$t('delivery_info_panel')" hide-validate-button>
    <div class="panel-body panel-text">
      <div class="p-4" v-if="cart.cartDelivery">

        <h5 class="mb-3">Informations sur le prix de la livraison</h5>

        <p class="mb-1">Gain du livreur sur cette course : {{cart.cartDelivery.courierPrice.price}}</p>
        <p class="mb-1">À votre charge : {{cart.values.deliveryFees.price}}</p>
        <p class="mb-1">le reste est pris en charge par votre restaurateur.</p>
        <h6 class="mt-3">Détails de la course :</h6>
        <p class="mb-1">Distance estimée : {{cart.cartDelivery.distance.distance}}.</p>
        <p class="mb-1">Temps de trajet estimé : {{ Math.round(cart.cartDelivery.distance.value / 1000 * 5) }} minutes.</p>
        <p class="mb-1">Temps moyen d’approche & d’attente au restaurant : 8 min.</p>
        <br>

        <h6>Bon à savoir :</h6>
        <p>Sur toutes les plateformes, les livreurs indépendants sont payés seulement pendant la course.</p>
        <p>Les temps morts, le trajet retour ou les temps d’approche vers le restaurant ne sont pas comptabilisés dans sa rémunération.</p>


      </div>
      <div class="panel-bottom"></div>
    </div>
  </app-panel>
</template>

<script>
import PanelTemplate from "@/mobileComponents/PanelTemplate";
import {mapGetters} from 'vuex'

export default {
  components: {
    'app-panel': PanelTemplate
  },
  computed: {
    ...mapGetters(['cart', 'merchant'])
  }
}
</script>
