<template>
  <app-panel event-name="openOrderTime"
             close-event-name="closeOrderTime"
             :hide-nav="!showModalElements"
             :hide-validate-button="!showModalElements"
             :title="$t('when_you_want_order')"
             :validate-button="$t('save')"
             :size="size"
             :is-loading="isLoading"
             @open="open"
             @validated="save"
             class="order-time-modal"
  >

    <div class="closed" v-if="init">

      <div class="close-container">
        <i class="material-icons notranslate" @click="$root.$emit('closeOrderTime')">close</i>
      </div>
      <img src="/img/closed.png">



      <template v-if="merchant.activated && merchant.shifts.nextOpenHour">
        <h1 v-if="merchant.shifts.openToday">{{ $t('order_time.title_today', {hours: merchant.shifts.openDateAt})}}</h1>
        <h1 v-else>{{ $t('order_time.title', {hours: merchant.shifts.openDateAt})}}</h1>

        <template v-if="dateSlots.length > 0 && cart.type !== 'on_site_counter' && cart.type !== 'on_site'">
          <span v-if="merchant.shifts.openToday">{{ $t('order_time.reopen_today', {hours: merchant.shifts.openDateAt }) }}</span>
          <span v-else>{{ $t('order_time.reopen_at', {hours: merchant.shifts.openDateAt }) }}</span>
          <span>{{ $t('order_time.preorder_now') }}</span>
        </template>
        <template v-else>
          <span>{{ $t('order_time.preorder_not_available') }}</span>
          <span>{{ $t('order_time.preorder_not_available_2') }}</span>
        </template>



        <div class="closed-hours-selector" v-if="dateSlots.length > 0 && cart.type !== 'on_site_counter' && cart.type !== 'on_site'">
          <template v-if="date !== 'other'">
            <select class="form-select" v-model="date">
              <option :value="d.value" v-for="d in dateSlots">{{ d.today ? $t('system.today') : d.dateString }}</option>
              <option value="other">Choisir une autre date</option>
            </select>
          </template>
          <template v-else>
            <input type="date" id="datepicker"
                   class="form-select-date-small"
                   :class="{'form-select-date-small-error': errors.find(e => e.error === 'datepicker')}"
                   placeholder="Choisir une date"
                   v-model="pickerDate"
                   :min="minDate"
                   :max="maxDate">
          </template>

          <select class="form-select" v-model="hours" v-if="hourSlots.length > 0 && !errors.find(e => e.error === 'datepicker')">
            <option :value="h.value" v-for="h in hourSlots">{{h.label}}</option>
          </select>
          <div class="closed-select" v-else-if="hourSlots.length === 0 && !errors.find(e => e.error === 'datepicker') && pickerDate">
            Fermé ce jour là
          </div>
        </div>

        <div class="order-hour-error text-center">
          <small class="text-danger" v-if="errors.find(e => e.error === 'datepicker')">{{errors.find(e => e.error === 'datepicker').message}}</small>
        </div>


        <div class="action" v-if="dateSlots.length > 0 && cart.type !== 'on_site_counter' && cart.type !== 'on_site'">
          <ui-button color="primary" @click="initDateSelector" size="large" style="width: 220px">{{$t('cta.order')}}</ui-button>
        </div>
        <div class="action" v-else>
          <ui-button color="primary" style="width: 200px" @click="close" size="large">{{$t('cta.ok')}}</ui-button>
        </div>

      </template>
      <template v-else>
        <h1>{{ $t('order_time.order_unavailable')}}</h1>
        <span>{{$t('order_time.order_closed')}}</span>

        <div class="action">
          <ui-button color="primary" style="width: 200px" @click="close" size="large">{{$t('cta.ok')}}</ui-button>
        </div>
      </template>



    </div>

    <div class="order-time-container" v-if="!init">
      <div class="order-time-setup">
        <ui-alert v-if="error" :dismissible="false" type="error">{{error}}</ui-alert>

        <div class="time-setup-title">
          {{$t('order_type')}}
        </div>
        <div class="order-type-selector">
          <ui-radio name="type" v-model="orderType" color="green" v-if="merchant.options.deliveryEnabled" trueValue="delivery">{{$t('in_delivery')}}</ui-radio>
          <ui-radio name="type" v-model="orderType" color="green" v-if="merchant.options.pickupEnabled" trueValue="pickup">{{$t('in_pickup')}}</ui-radio>
        </div>

        <div class="time-setup-title" >
          {{$t('order_config_time')}}
        </div>



        <div class="order-type">
          <ui-radio name="type" v-model="orderOrderType" trueValue="when_possible">
            <span>{{$t('when_possible')}}</span>

            <div class="time" v-if="!merchant.shifts.open && merchant.shifts.nextOpenHour">
              {{ merchant.shifts.nextOpenHour }}
            </div>
            <div class="time" v-if="merchant.shifts.open">
              {{ $t('order_time_now', {time: nowDuration}) }}
            </div>

          </ui-radio>
          <ui-radio name="type" v-model="orderOrderType" trueValue="schedule_for_later">
            <span>{{$t('schedule_for_later')}}</span>
          </ui-radio>
        </div>

        <div class="order-hours">
          <template v-if="date !== 'other'">
            <select class="form-select" v-model="date" :disabled="orderOrderType !== 'schedule_for_later'">
              <option :value="d.value" v-for="d in dateSlots">{{ d.today ? $t('system.today') : d.dateString }}</option>
              <option value="other">Choisir une autre date </option>
            </select>
          </template>
          <template v-else>
            <input type="date"
                   id="datepicker"
                   :class="{'form-select-error': errors.find(e => e.error === 'datepicker')}"
                   class="form-select-date"
                   v-model="pickerDate"
                   :min="minDate"
                   :max="maxDate"
                   placeholder="Entrez une date">
          </template>



          <select class="form-select" v-model="hours" :disabled="orderOrderType !== 'schedule_for_later' || hourSlots.length === 0">
            <option :value="h.value" v-for="h in hourSlots">{{h.label}}</option>
          </select>
        </div>

        <div class="order-hour-error" v-if="errors.find(e => e.error === 'datepicker')">
          <i class="material-icons notranslate text-danger">error_outline</i>
          <small class="text-danger">{{errors.find(e => e.error === 'datepicker').message}}</small>
        </div>

      </div>

    </div>


  </app-panel>
</template>

<style lang="scss">

.order-hour-error
{
  padding: 10px 0;
  line-height: 1;
  display: flex;
  align-items: center;
  i
  {
    font-size: 1em;
    margin-right: 5px;
  }
  small
  {
    font-weight: 600;
  }
}

.closed-hours-selector
{
  display: flex;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: column;
  .form-select
  {
    min-width: 200px;
    border: 0;
    border-radius: 0;
    background-color: #fff;
    &:nth-child(2)
    {
      border-top: 1px solid #ccc;
    }
  }
  .closed-select
  {
    border-top: 1px solid #ccc;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    color: #797979;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
  }
}

.form-select-date
{
  background-color: #F2F4F8;
  border: 0;
  flex: 1;
  margin: 0 10px;
  outline: none !important;
  padding: 12px 20px;
  min-height: 48px;
  padding: 12px 20px;
  font-size: 1rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border-radius: .25rem;
  width: calc(50% - 20px);
  appearance: none;
  -webkit-appearance: none;
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}
.form-select-date-small
{
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  height: 45px;
  min-height: 45px;
  min-width: 200px;
  background-color: #fff;
  width: 100%;
  border: 0;
  flex: 1;
  outline: none !important;
  padding: .65rem 2.25rem .65rem .75rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  font-size: 1rem;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0 !important;
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}

.form-select-error {
  box-shadow: 0 0 0 2px rgba(227, 28, 28, 0.4) !important;
  background-color: #fff1f1 !important;
}
.form-select-date-small-error {
  background-color: #ffeded !important;
}
</style>


<script>
import PanelTemplate from "@/mobileComponents/PanelTemplate";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  components: {
    'app-panel': PanelTemplate
  },
  data(){
    const minDate = moment().format('YYYY-MM-DD');
    const maxDate = moment().add(60, 'days').format('YYYY-MM-DD');
    const defaultDate = moment().add(7, 'days').format('YYYY-MM-DD');

    return {
      init: false,
      orderType: 'delivery',
      orderOrderType: 'when_possible',
      date: null,
      hours: null,
      error: null,
      errors: [],
      dateSlots: [],
      hourSlots: [],
      next: false,
      minDate: minDate,
      maxDate: maxDate,
      pickerDate: defaultDate,
      isLoading: false,
    }
  },
  methods: {
    ...mapActions(['setOrderType', 'setOrderTime', 'getHourSlots', 'getDateSlots']),
    endInit(){
      this.init = false;
    },
    async open(payload){
      this.error = null;
      this.errors = [];
      if(payload && payload.initiated){
        this.endInit();
      }

      if(this.dateSlots.length === 0 || this.merchant.activated === false){
        this.init = true;
      }

      this.next = false;
      this.orderType = this.cart.type;

      this.orderOrderType = this.cart.laterOrder !== null ? 'schedule_for_later' : 'when_possible';

      if(this.cart.laterOrder){
        if(!this.dateSlots.find(d => d.value === this.cart.laterOrder.date)){
          this.pickerDate = this.cart.laterOrder.date;
          this.date = 'other';
        }
        else{
          this.date = this.cart.laterOrder.date;
        }
        this.hours = this.cart.laterOrder.hour;
      }


      if(payload && payload.next){
        this.orderOrderType = 'schedule_for_later';
        this.date = moment.unix(payload.next).format('YYYY-MM-DD');
        console.log(this.date);
        await this.loadHourSlot();
        this.next = moment.unix(payload.next);
        const hour = this.next.format('HH:mm');
        if(this.hourSlots.find(e => e.value === hour)){
          this.hours = hour;
        }
        else if(this.hourSlots.length > 0){
          this.hours = false;
          this.hourSlots.forEach((h) => {
            if(moment(this.date + ' ' + h.value ).unix() >= payload.next && !this.hours){
              this.hours = h.value;
              return;
            }
          })
        }
      }

    },
    initDateSelector(){
      this.orderOrderType = 'schedule_for_later';
      this.save();
    },
    close(){
      this.$root.$emit('closeOrderTime');
    },
    loadDates(){
      this.getDateSlots()
          .then((data) => {
            this.dateSlots = data.slots;
            if(this.dateSlots.length > 0 && !this.date){
              this.date = data.slots[0].value;
            }
            if(this.dateSlots.length === 0){
              this.init = true;
            }
          })
          .catch((err) => {

          });
    },
    async save(){
      this.isLoading = true;
      this.errors = [];

      if(this.orderType != this.cart.type){
        if(this.orderType === 'delivery' || this.orderType === 'pickup'){
          await this.setOrderType(this.orderType);
        }
      }
      if(this.hourSlots.length === 0 && this.orderOrderType !== 'when_possible'){
        this.errors.push({error: 'datepicker', message: "L'établissement est fermé à cette date"});
        this.hours = null;
        this.isLoading = false;
        return;
      }
      if(!this.hours && this.orderOrderType !== 'when_possible'){
        this.errors.push({error: 'datepicker', message: "Pas d'horaire selectionné."});
        this.isLoading = false;
        return;
      }


      let date = this.date;
      if(date === 'other'){
        date = moment(this.pickerDate).format('YYYY-MM-DD');
      }
      setTimeout(() => {
        this.setOrderTime({time: this.orderOrderType, laterAtDate: date, laterAtHour: this.hours})
            .then(() => {
              this.isLoading = false
              this.close();
            })
            .catch((e) => {
              this.isLoading = false
              if(e){
                this.error = e;
              }
            });
      }, 200)
    },
    loadHourSlot(){
      let date = this.date;
      if(this.date === 'other'){
        this.hourSlots = [];
        date = moment(this.pickerDate).format('YYYY-MM-DD');
      }

      return this.getHourSlots({date:date, type:this.orderType})
          .then((slots) => {
            this.hourSlots = slots;
            if(this.hourSlots.length > 0 && !this.hours){
              this.hours = this.hourSlots[0].value;
            }
            if(this.hourSlots.length > 0 && this.hours){
              if(!this.hourSlots.find(e => e.value === this.hours)){
                this.hours = this.hourSlots[0].value;
              }
            }
            if(this.hourSlots.length === 0){
              this.errors.push({error: 'datepicker', message: "L'établissement est fermé à cette date"});
            }
          })
          .catch((error) => {
            this.error = error;
          });
    },
    validate(){
      this.errors = [];
      this.error = null;

      if(this.date === 'other'){
        if(moment(this.pickerDate).isBefore(moment().startOf('day'))){
          this.errors.push({error: 'datepicker', message: 'La date ne peux pas être dans le passé'});
        }
        if(moment(this.pickerDate).isAfter(moment().add(60, 'days').startOf('day'))){
          this.errors.push({error: 'datepicker', message: "Précommandes possible max 60 jours à l'avance"});
        }
      }

    }
  },
  computed: {
    ...mapGetters(['merchant', 'cart']),
    size(){
      return this.init ? 'semi' : 'fullscreen';
    },
    showModalElements(){
      return this.init === false;
      this.open();
    },
    nowDuration(){
      if(this.cart.type === 'delivery' && this.cart.cartDelivery && this.cart.OrderProducts.length > 0 && this.cart.cartDelivery.duration.minDuration !== 0){
        return this.cart.cartDelivery.duration.minDuration + '-' + this.cart.cartDelivery.duration.maxDuration;
      }
      if(this.cart.type === 'delivery'){
        return this.merchant.estimatedTime;
      }
      if(this.cart.type === 'pickup'){
        return this.merchant.estimatedPreparationTime;
      }
    },
  },
  mounted() {
    this.loadDates();

    this.orderOrderType = this.cart.laterOrder !== null ? 'schedule_for_later' : 'when_possible';


    if(this.cart.laterOrder){
      if(!this.dateSlots.find(d => d.value === this.cart.laterOrder.date)){
        this.pickerDate = this.cart.laterOrder.date;
        this.date = 'other';
      }
      else{
        this.date = this.cart.laterOrder.date;
      }
      this.hours = this.cart.laterOrder.hour;
    }

    if(!this.merchant.shifts.open && this.cart.laterOrder === null && this.cart.type !== 'on_site'){
      setTimeout(() => {
        this.$root.$emit('openOrderTime');
      }, 1500);

      this.time = 'schedule_for_later';
      this.init = true;
      if(!this.date){
        this.date = this.merchant.shifts.openAtDate;
      }
    }
  },
  watch: {
    date(val, old){
      if(old !== val && val === 'other'){
        this.$nextTick(() => {
          // open date picker ?
        });
      }
      this.loadHourSlot();
    },
    pickerDate(){
      this.validate();
      this.loadHourSlot();
    },
    orderType(){
      if(this.dateSlots.length > 0 && this.date){
        this.loadHourSlot();
      }
    }
  }
}
</script>
