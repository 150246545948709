<template>
  <app-panel type="panel"
             transition="fade"
             event-name="openExclusiveReactive"
             close-event-name="closeExclusiveReactive"
             hide-validate-button
             class="exclusive-product-modal"
             @open="open"
             size="medium"
             hide-nav>

    <div class="exclusive-container" v-if="category">
      <h2>{{$t('exclusive_reactive')}}</h2>
      <p>{{$t('exclusive_reactive_p', {category: this.category.title})}}</p>

      <div class="exclusive__action">

        <ui-button color="light" @click="close">Annuler</ui-button>
        <ui-button color="primary" :loading="isLoading" @click="deleteNoExclusiveProduct">{{$t('exclusive_delete')}}</ui-button>

      </div>
    </div>

    </app-panel>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import PanelTemplate from "@/mobileComponents/PanelTemplate";
export default {
  components: {'app-panel': PanelTemplate},
  data() {
    return {
      category: null,
      product: null,
      quantity: null,
      selectedOptions: null,
      comment: null,
      isLoading: false,
      changeDate: null,
      otherProducts: 0
    }
  },
  methods: {
    ...mapActions(['clearNonExclusiveCart', 'addToCart']),
    open(data){
      this.category = data.category;
      this.selectedOptions = data.selectedOptions;
      this.quantity = data.quantity;
      this.comment = data.comment;
      this.changeDate = data.changeDate;
      this.product = data.product;

      this.otherProducts = 0;
      this.cart.OrderProducts.forEach((p) => {
        if(p.categoryId !== this.category.id){
          this.otherProducts += parseInt(p.quantity);
        }
      });
    },
    close(){
      this.$root.$emit('closeProduct');
      this.$root.$emit('closeExclusiveReactive');
    },
    async deleteNoExclusiveProduct(){


      await this.clearNonExclusiveCart(this.category.id);
      this.isLoading = true;
      this.addToCart({options: this.selectedOptions, productId: this.product.id, quantity: this.quantity, comment: this.comment, changeDate: this.category.availability && this.category.availability.mode === 'uniqueDate'}).then(() => {
        this.isLoading = false;
        this.$root.$emit('addToCart', {product:this.product, quantity:this.quantity});
        this.close();
      });
    }
  },
  computed: {
    ...mapGetters(['menu', 'cart'])
  },
  mounted() {

  }
}
</script>
