<template>
  <div class="order-config">
    <div class="order-config-box">
      <div class="config-row">
        <div class="field type-field">
          <div class="icon">
            <i class="material-icons notranslate" v-if="valueType.value === 'delivery'">moped</i>
            <i class="material-icons notranslate" v-if="valueType.value === 'pickup'">directions_run</i>
            <i class="material-icons notranslate" v-if="valueType.value === 'on_site'">restaurant</i>
            <i class="material-icons notranslate" v-if="valueType.value === 'on_site_counter'">dinner_dining</i>
          </div>
          <select v-model="valueType">
            <option :value="option" :disabled="option.value === 'delivery' && !cart.deliveryAvailable" v-for="option in optionType">{{option.label}}</option>
          </select>
        </div>


        <template v-if="showTime">
          <div class="field border-top" :class="{'field-disabled': orderDisabled}" @click="$root.$emit('openOrderTime', {initiated: true})">
            <div class="icon">
              <i class="material-icons notranslate">update</i>
            </div>
            <div class="select">
              <template v-if="orderDisabled">
                <span class="value">{{$t('merchant_closed')}}</span>
              </template>
              <template v-else-if="!merchant.shifts.open && !cart.laterOrder">
                <span class="value disabled">{{$t('choose_shift')}}</span>
              </template>
              <template v-else>
                <template v-if="cart.type === 'delivery'">
                  <span class="value" v-if="cart.laterOrder && !cart.laterOrder.tomorrow && !cart.laterOrder.today">{{$t('later_at', {date:cart.laterOrder.descriptiveDate, hour: cart.laterOrder.dateHour, max:cart.laterOrder.dateHourMax})}}</span>
                  <span class="value" v-if="cart.laterOrder && cart.laterOrder.today">{{$t('later_today_at', {hour: cart.laterOrder.dateHour, max:cart.laterOrder.dateHourMax})}}</span>
                  <span class="value" v-if="cart.laterOrder && cart.laterOrder.tomorrow">{{$t('later_tomorrow_at', {hour: cart.laterOrder.dateHour, max:cart.laterOrder.dateHourMax})}}</span>
                </template>
                <template v-if="cart.type === 'pickup'">
                  <span class="value" v-if="cart.laterOrder && !cart.laterOrder.tomorrow && !cart.laterOrder.today">{{$t('later_at_precise', {date:cart.laterOrder.descriptiveDate, hour: cart.laterOrder.dateHour})}}</span>
                  <span class="value" v-if="cart.laterOrder && cart.laterOrder.today">{{$t('later_today_at_precise', {hour: cart.laterOrder.dateHour})}}</span>
                  <span class="value" v-if="cart.laterOrder && cart.laterOrder.tomorrow">{{$t('later_tomorrow_at_precise', {hour: cart.laterOrder.dateHour})}}</span>
                </template>
                <span class="value" v-if="!cart.laterOrder">{{ $t('web_cart_delivery_duration', {time:nowDuration}) }}</span>
              </template>
            </div>
          </div>
        </template>



          <div class="config-row" ref="orderConfigAddress" v-if="showAddress">
            <div class="field border-top" :class="{'field-error':cart.cartDelivery && cart.cartDelivery.address && !cart.cartDelivery.deliveryAvaible}" @click="addressClick" ref="address">
              <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" version="1" viewBox="0 0 24 24" width="25" height="25">
                  <path d="M12,2c4.2,0,8,3.22,8,8.2c0,3.32-2.67,7.25-8,11.8c-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2z M18,10.2 C18,6.57,15.35,4,12,4s-6,2.57-6,6.2c0,2.34,1.95,5.44,6,9.14C16.05,15.64,18,12.54,18,10.2z M12,12c-1.1,0-2-0.9-2-2s0.9-2,2-2 s2,0.9,2,2S13.1,12,12,12z"/>
                </svg>
              </div>
              <div class="select">
                <span class="placeholder" v-if="!cart.cartDelivery || !cart.cartDelivery.addressDefined">{{$t('order_config_set_address')}}</span>
                <span class="value value-elipse" v-if="cart.cartDelivery && cart.cartDelivery.addressDefined">{{cart.cartDelivery.address.address}}, {{cart.cartDelivery.address.city}}</span>
              </div>
            </div>
          </div>

      </div>
    </div>


    <div class="order-config-error" v-if="cart.cartDelivery && cart.cartDelivery.address && !cart.cartDelivery.deliveryAvaible">
      <small>{{$t('address_too_far')}} <br> <a href="#" @click.prevent="setPickup()" v-if="merchant.options.pickupEnabled">{{$t('set_order_pickup_question')}}</a></small>
      <i class="material-icons notranslate">error_outline</i>
    </div>

    <div class="order-config-message" v-else-if="cart.cartDelivery && cart.cartDelivery.addressMessage">
      <small>{{cart.cartDelivery.addressMessage}}</small>
      <i class="material-icons notranslate">celebration</i>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import tippy from 'tippy.js/esm';

export default {
  data(){
    return {
      optionType: [],
      valueType: '',

      optionTime: [],
      valueTime: '',

      showAddress: false,
      showTime: false,
      optionAddress: [],
      valueAddress: ''
    }
  },
  methods: {
    ...mapActions(['setOrderType', 'setOrderAddress']),
    setType(){
      this.optionType = [];


      if(this.merchant.options.onsiteCounterEnabled){
        this.optionType.push({label: this.$t('type_on_site_counter'), value: 'on_site_counter'})
        if(this.cart.type === 'on_site_counter'){
          this.valueType = this.optionType.slice(-1).pop()
        }
      }

      if(this.merchant.options.onSiteEnabled){
        this.optionType.push({label: this.$t('order_config_on_site'), value: 'on_site'})
        if(this.cart.type === 'on_site'){
          this.valueType = this.optionType.slice(-1).pop()
        }
      }

      if(this.merchant.options.pickupEnabled){
        this.optionType.push({label: this.$t('order_config_pickup'), value: 'pickup'})
        if(this.cart.type === 'pickup'){
          this.valueType = this.optionType.slice(-1).pop()
        }
      }
      if(this.merchant.options.deliveryEnabled){
        this.optionType.push({label: this.$t('order_config_delivery'), value: 'delivery'})
        if(this.cart.type === 'delivery'){
          this.valueType = this.optionType.slice(-1).pop()
        }
      }

      if(!this.valueType){
        this.valueType = this.optionType.slice(-1).pop()
      }
    },
    setPickup(){
      this.valueType = {label: this.$t('order_config_pickup'), value: 'pickup'};
    },
    setTime(){
      //if(this.merchant.shifts.open){
        this.optionTime.push({label: this.$t('order_config_time_now'), value: 'now'});
        this.valueTime = this.optionTime[0];
      //}
      this.optionTime.push({label: this.$t('order_config_time_select_date'), value: 'select'});
    },
    addressClick(){
        return this.$root.$emit('openAddressList', {transition: 'slide-top'});
    },
    addressTooltip(){
      if(this.cart.type !== 'delivery'){
        return;
      }

      if(this.cart && this.cart.cartDelivery.address === null){
        /*
        tippy(this.$refs.orderConfigAddress, {
          content: this.$t('cart_delivery_no_address_error'),
          placement: 'bottom',
          arrow: 'default',
          trigger: 'focus',
          zIndex: 50
        });*/

        //this.$refs.orderConfigAddress.focus();
      }
      else{
        this.$refs.orderConfigAddress.blur();
      }
    }
  },
  computed: {
    ...mapGetters(['merchant', 'cart', 'user']),
    orderDisabled(){
      return !this.merchant.shifts.open && this.merchant.shifts.exceptionalHours && this.merchant.shifts.exceptionalHours.preorderAllowed === false;
    },
    nowDuration(){
      if(this.cart.type === 'delivery' && this.cart.cartDelivery && this.cart.OrderProducts.length > 0 && this.cart.cartDelivery.duration.minDuration !== 0){
        return this.cart.cartDelivery.duration.minDuration + '-' + this.cart.cartDelivery.duration.maxDuration;
      }
      if(this.cart.type === 'delivery'){
        return this.merchant.estimatedTime;
      }
      if(this.cart.type === 'pickup'){
        return this.merchant.estimatedPreparationTime;
      }
    },
  },
  watch: {
    valueTime(val){
      if(val.value === 'select'){
        this.valueTime = '';
        // Show date selector
      }
    },
    valueType(val, old){
      this.showTime = true;
      if(val.value === 'delivery'){
        this.showAddress = true;
        this.showTime = true;
      }
      else{
          this.showAddress = false;
      }
      if(val.value === 'pickup'){
        this.showTime = true;
      }
      if(val.value === 'on_site'){
        this.showTime = false;
      }
      if(val.value === 'on_site_counter'){
        this.showTime = false;
      }
      if(old && val.value !== old.value){
        this.setOrderType(val.value);
      }
    },
    cart: {
      deep: true,
      handler(val){
        this.valueType = this.optionType.find(e => e.value === val.type);
        this.$nextTick(() => {
          this.addressTooltip();
        });
      }
    }
  },
  mounted() {
    this.setType();
    this.setTime();
    this.$nextTick(() => {
      this.addressTooltip();
    });
    this.$root.$on('closeCart')
  }
}
</script>
