<template>
  <app-panel type="panel"
             transition="fade"
             event-name="openChangeDateCart"
             close-event-name="closeChangeDateCart"
             hide-validate-button
             class="exclusive-product-modal"
             @open="open"
             size="medium"
             hide-nav>

    <div class="exclusive-container" v-if="category">
      <h2>{{$t('exclusive_proactive')}}</h2>
      <p>{{$t('change_date_modal', {category: category.title, date: category.availability.availableDateHuman})}}</p>

      <div class="exclusive__action">
        <ui-button color="light" @click="close">Annuler</ui-button>
        <ui-button color="primary" :loading="isLoading" @click="add">{{$t('change_date_action')}}</ui-button>
      </div>
    </div>

  </app-panel>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import PanelTemplate from "@/mobileComponents/PanelTemplate";
export default {
  components: {'app-panel': PanelTemplate},
  data() {
    return {
      category: null,
      product: null,
      quantity: null,
      selectedOptions: null,
      comment: null,
      isLoading: false,
      changeDate: null,
      otherProducts: 0,
      otherCategory: false
    }
  },
  methods: {
    ...mapActions(['clearExclusiveCart', 'addToCart']),
    open(data){
      this.category = data.category;
      this.selectedOptions = data.selectedOptions;
      this.quantity = data.quantity;
      this.comment = data.comment;
      this.changeDate = data.changeDate;
      this.product = data.product;
    },
    close(){
      this.$root.$emit('closeProduct');
      this.$root.$emit('closeChangeDateCart');
    },
    add(){
      this.isLoading = true;
      this.addToCart({options: this.selectedOptions, productId: this.product.id, quantity: this.quantity, comment: this.comment}).then(() => {
        this.isLoading = false;
        this.$root.$emit('addToCart', {product:this.product, quantity:this.quantity});
        this.close();
      });
    }
  },
  computed: {
    ...mapGetters(['menu', 'cart'])
  },
  mounted() {

  }
}
</script>
