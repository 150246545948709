<template>
  <div class="merchant-header">
    <div class="merchant-banner" :style="{'background-image': 'url('+merchant.backgroundImages+')'}"></div>
    <div class="merchant-infos">

      <template v-if="merchant.activated">
        <span class="open"   @click="$root.$emit('openHours')" v-if="merchant.shiftsOnSite.open && merchant.activated"><i class="material-icons notranslate">access_time</i>{{ $t('header_open', {hours: merchant.shiftsOnSite.closeAt}) }}</span>
        <span class="closed" @click="$root.$emit('openHours')" v-if="!orderDisabled && !merchant.shiftsOnSite.open && merchant.shiftsOnSite.active && merchant.shiftsOnSite.openAt === null"><i class="material-icons notranslate">access_time</i>{{ $t('header_closed') }}</span>
        <span class="closed" @click="$root.$emit('openHours')" v-if="!orderDisabled && !merchant.shiftsOnSite.open && merchant.shiftsOnSite.active && merchant.shiftsOnSite.openAt !== null"><i class="material-icons notranslate">access_time</i>{{ $t('header_closed_until', {time:merchant.shiftsOnSite.openAt}) }}</span>
        <span class="closed" @click="$root.$emit('openHours')" v-if="orderDisabled && !merchant.shiftsOnSite.open && merchant.shiftsOnSite.active && merchant.shiftsOnSite.openAt === null"><i class="material-icons notranslate">access_time</i>{{ $t('header_closed_vacation', {date: merchant.shiftsOnSite.exceptionalHours.endDate}) }}</span>
        <span class="closed" @click="$root.$emit('openHours')" v-if="!merchant.shiftsOnSite.active"><i class="material-icons notranslate">access_time</i>{{ $t('order_no_available', {merchant:merchant.name}) }}</span>
      </template>
      <span class="closed" v-if="!merchant.activated"><i class="material-icons notranslate">close</i>{{ $t('merchant_disabled') }}</span>

      <a v-if="merchant.phone" :href="'tel:'+merchant.phone"><i class="material-icons notranslate">phone</i>{{ merchant.phone }}</a>

      <span @click="$root.$emit('openMapPanel')"><i class="material-icons notranslate">place</i>{{ merchant.address.address }}, {{ merchant.address.zip }} {{ merchant.address.city }}</span>

      <span v-if="merchant.rating.count > 0"><i class="material-icons notranslate">star</i>{{ merchant.rating.rating }} / 5 {{ $t('header_rating', {rating: merchant.rating.count}) }}</span>

      <span v-if="merchant.estimatedTime && cart.type === 'delivery'"><i class="material-icons notranslate">shopping_bag</i> {{$t('delivered_habitualy_in', {duration:merchant.estimatedTime})}}</span>
      <span v-if="merchant.estimatedTime && (cart.type === 'pickup' || cart.type === 'on_site_counter' || cart.type === 'on_site')"><i class="material-icons notranslate">shopping_bag</i> {{$t('ready_habitualy_in', {duration:merchant.estimatedPreparationTime})}}</span>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['merchant', 'cart']),
    orderDisabled(){
      return !this.merchant.shifts.open && this.merchant.shifts.exceptionalHours && this.merchant.shifts.exceptionalHours.preorderAllowed === false;
    },
  }
}
</script>
