<template>
  <app-panel type="panel" event-name="openHours" class="panel-text" :title="$t('hours')" hide-validate-button>
    <div class="panel-body">
      <div class="p-4">
        <ui-alert type="error" class="mb-4" :dismissible="false" remove-icon v-if="orderDisabled">{{ $t('merchant_closed_order_disabled', {date: this.merchant.shifts.exceptionalHours.endDate})}}</ui-alert>

        <table class="week-hours">
          <tr v-for="shift in shiftsOnSite">
            <td class="pt-2 pr-3"><b>{{shift[0].weekDayFormated}}</b></td>
            <td class="hours pt-2">
              <span v-if="s.start && s.end" v-for="s in shift" class="ml-4">{{s.start}} - {{s.end}}</span>
              <span v-if="!s.start || !s.end" v-for="s in shift" class="ml-4 text-muted">Fermé</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </app-panel>
</template>

<script>
import PanelTemplate from "@/mobileComponents/PanelTemplate";
import { mapGetters } from 'vuex'

export default {
  components: {
    'app-panel': PanelTemplate
  },
  computed: {
    ...mapGetters(['shiftsOnSite', 'merchant']),
    orderDisabled(){
      return !this.merchant.shiftsOnSite.open && this.merchant.shiftsOnSite.exceptionalHours && this.merchant.shiftsOnSite.exceptionalHours.preorderAllowed === false;
    }
  }
}
</script>
