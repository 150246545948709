<template>
  <app-panel event-name="openNoAccount" :is-loading="isLoading" :title="$t('order_without_account')" close-event-name="closeNoAccount">

    <div class="panel-body">

      <ui-alert type="error" v-if="error" @dismiss="error = false">{{error}}</ui-alert>

      <form @submit="registerButton" class="form-modal-template">
        <h4 class="font-weight-bold mb-4">Informations obligatoires pour traiter votre commande</h4>

        <div class="form-group">
          <label>{{ $t('auth_register_label_firstname') }}</label>
          <input type="text" v-model="user.firstname" class="form-control" :class="{'is-invalid': errors.some(e => e.error === 'firstname')}">

          <div v-if="errors.some(e => e.error === 'firstname')"><small class="text-danger">{{errors.find(e => e.error === 'firstname').message}}</small></div>
        </div>

        <div class="form-group">
          <label>{{ $t('auth_register_label_lastname') }}</label>
          <input type="text" v-model="user.lastname" class="form-control" :class="{'is-invalid': errors.some(e => e.error === 'lastname')}">
          <div v-if="errors.some(e => e.error === 'lastname')"><small class="text-danger">{{errors.find(e => e.error === 'lastname').message}}</small></div>
        </div>


        <div class="form-group">
          <label>{{ $t('auth_login_label_phone') }}</label>
          <vue-tel-input v-model="user.phone" default-country="fr" mode="international" :placeholder="$t('auth_login_placeholder_phone')"></vue-tel-input>
          <div v-if="errors.some(e => e.error === 'phone')"><small class="text-danger">{{errors.find(e => e.error === 'phone').message}}</small></div>
          <div style="line-height: 1.1" class="mt-2">
            <small>{{$t('check_your_phone')}}</small>
          </div>
        </div>

        <div class="form-group">
          <label class="font-weight-normal cursor-pointer" for="addInvoiceEmail"><input type="checkbox" id="addInvoiceEmail" v-model="addInvoiceEmail" class="mr-2">{{ $t('auth_login_label_add_email') }}</label>
        </div>
        <div class="form-group" v-if="addInvoiceEmail">
          <label>{{ $t('auth_login_label_email') }}</label>
          <input type="text" v-model="user.email" placeholder="exemple@email.com" class="form-control" :class="{'is-invalid': errors.some(e => e.error === 'email')}">
          <div v-if="errors.some(e => e.error === 'email')"><small class="text-danger">{{errors.find(e => e.error === 'email').message}}</small></div>
          <div v-if="errors.email">
            <small class="text-danger" v-for="error in errors.email">{{error}}</small>
          </div>
        </div>



      </form>

    </div>


    <div slot="footer">
      <div class="modal-fixed-bottom-button">
        <ui-button color="primary" @click="checkPhoneTrigger" size="large" :loading="isLoading">{{ $t('save') }}</ui-button>
      </div>
    </div>


    <div class="validate-phone-container" v-if="checkPhone">
      <div class="validate-phone">
        <span>{{$t('check_phone')}}</span>
        <strong>{{user.phone}}</strong>
        <ui-button class="w-100" color="primary" :loading="isLoading" @click="registerButton">{{$t('is_my_phone')}}</ui-button>
        <ui-button class="w-100" color="clear" @click="checkPhone = false">{{$t('i_edit_my_phone')}}</ui-button>
      </div>
    </div>

  </app-panel>
</template>

<script>
import PanelTemplate from "@/mobileComponents/PanelTemplate";
import {mapActions, mapGetters} from 'vuex'

export default {
  data(){
    return {
      isLoading: false,
      addInvoiceEmail: null,
      user: {
        firstname: null,
        lastname: null,
        orderToken: null,
        phone: null,
        email: null
      },
      error: false,
      checkPhone: false,
      errors: []
    }
  },
  components: {
    'app-panel': PanelTemplate
  },
  methods: {
    ...mapActions(['noAccountAction']),


    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatePhoneNumber(phone) {
      const re = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;
      return re.test(phone.split(' ').join(''));
    },

    validate(){
      if(!this.user.firstname){
        this.errors.push({message: this.$t('errors.firstname_required'), error: "firstname"})
      }
      if(!this.user.lastname){
        this.errors.push({message: this.$t('errors.lastname_required'), error: "lastname"})
      }
      if(!this.user.phone){
        this.errors.push({message: this.$t('errors.phone_required'), error: "phone"})
      }
      if(this.user.phone && !this.validatePhoneNumber(this.user.phone)){
        this.errors.push({message: this.$t('errors.phone_not_valid'), error: "phone"})
      }
      if(!this.user.email && this.addInvoiceEmail){
        this.errors.push({message: this.$t('errors.email_required'), error: "email"})
      }
      if(this.user.email && this.addInvoiceEmail && !this.validateEmail(this.user.email)){
        this.errors.push({message: this.$t('errors.email_not_valid'), error: "email"})
      }
      return this.errors.length === 0;
    },

    checkPhoneTrigger() {
      this.error = false;
      this.errors = [];
      if(!this.validate()){
        return;
      }
      this.checkPhone = true;
    },

    registerButton(){
      this.error = false;
      this.errors = [];
      if(!this.validate()){
        return;
      }
      this.user.orderToken = this.orderToken;

      this.noAccountAction(this.user)
          .then((response) => {
            localStorage.setItem('bearer', response.access_token);
            location.href = location.origin + location.pathname + '?openCart=true';
          })
          .catch((errors) => {
            this.isLoading = false;
             this.checkPhone = false;

            if(errors === 'anonymous_order_disabled') {
              this.error = this.$t(errors);
            }

            this.errors = errors;
          });

    }
  },
  computed: {
    ...mapGetters(['orderToken'])
  }
}
</script>
