<template>
  <div class="three-d-secure-modal" v-if="isOpen">
    <h1>Gardez cette fenêtre ouverte</h1>
    <span>Après validation de l’opération par votre banque, veuillez revenir sur votre navigateur pour finaliser l’achat.</span>
    <div class="loading">
      <ui-progress-circular
          type="determinate"
          :progress="progress"
          color="white"
          :size="48"
          :stroke="8"
      ></ui-progress-circular>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      progress: 0,
      isOpen: false,
      url: false
    }
  },
  methods: {
    ...mapActions([]),
    loading(){
      this.progress = 0;
      const interval = setInterval(() => {
        this.progress += 5;
        if(this.progress >= 100){
          clearInterval(interval);
          window.location = this.url;
        }
      }, 150)
    },
    listen(){
      this.$root.$on('open3DSecure', (url) => {
        this.isOpen = true;
        console.log('ok');
        this.url = url;
        this.loading();
      });
    },
  },
  computed: {
    ...mapGetters([])
  },
  mounted() {
    this.listen();
  }
}
</script>
