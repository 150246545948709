<template>
  <div class="cart-floating-button-container" v-if="cart.type !== 'on_site' && cart && merchant.activated && cart.values.total.value !== 0 && cart.quantity !== 0">
    <div style="padding: 0 20px">
      <ui-button color="green" @click="openCart" size="large" class="cart-floating-button">
        <span>{{$t('see_cart')}}</span>
        <span>{{cart.values.totalProduct.price}}</span>
      </ui-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    openCart(){
      this.$root.$emit('openCart');
    }
  },
  computed: {
    ...mapGetters(['cart', 'user', 'merchant'])
  }
}
</script>
