<template>
  <app-panel event-name="openLogin" :title="$t('auth_login')" hide-validate-button>
    <div class="panel-body">
      <ui-alert type="error" remove-icon v-if="error" @dismiss="error = false">{{$t(error)}}</ui-alert>

      <div class="header-image-modal">
        <img src="../../assets/logo_square_XS.png">
      </div>


     <div class="form-modal-template">
        <div class="form-group">
          <label>{{ $t('auth_login_label_email') }}</label>
          <input v-model="email" tabindex="1" type="email" class="form-control">
        </div>

        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>{{ $t('auth_login_label_password') }}</label>
            <a href="https://auth.delicity.com/forgot-password" target="_blank">{{ $t('forgoten') }}</a>
          </div>
          <input v-model="password" tabindex="2" type="password" class="form-control">
        </div>

        <div class="action-group">
          <ui-button color="primary" @click="login" size="large" :loading="isLoginLoading">{{ $t('auth_login_action_login') }}</ui-button>
          <a href="#" @click.prevent="$root.$emit('openRegister')">{{ $t('no_account_for_now') }}</a>
        </div>
      </div>



</div>
  </app-panel>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import config from "@/config";
import PanelTemplate from "@/mobileComponents/PanelTemplate";

export default {
  data(){
    return {
      page: 'login',
      isLoginLoading: false,
      error: false,
      email: null,
      password: null
    }
  },
  components:{
    'app-panel': PanelTemplate
  },
  methods: {
    ...mapActions(['loginPassword', 'resetPasswordEmail']),
    login(){
      this.isLoginLoading = true;
      if(!this.email || !this.password){
        this.error = 'error_missing_field';
        this.isLoginLoading = false;
        return;
      }

      this.loginPassword({email: this.email, password: this.password}).then(() => {
        this.isLoginLoading = false;
        if(this.bearer){
          this.$root.$emit('closeAuth', true);
          this.close();
        }
        else{
          this.error = 'error_bad_account';
        }
      });
    },
    forgot(){
      this.isLoginLoading = true;
      this.error = false;
      if(!this.email){
        this.error = 'error_missing_field';
        this.isLoginLoading = false;
        return;
      }
      let redirect = encodeURI(location.origin + location.pathname + '?authed=true');
      this.resetPasswordEmail({email: this.email, return_url: redirect})
          .then(() => {
            this.page = 'forgot_success';
            this.isLoginLoading = false;
          })
          .catch((error) => {
            this.error = error;
            this.isLoginLoading = false;
          });
    }
  },
  computed: {
    ...mapGetters(['cart', 'userToken', 'bearer'])
  }
}
</script>
