<template>
  <div>
    <router-link :to="{name: 'checkout', params: {orderToken: user.currentOrder.token, merchantSlug: user.currentOrder.merchantSlug}}" class="merchant-current-order" v-if="user && user.order">
      <span>{{ $t('go_on_current_order') }}</span>
      <i class="material-icons notranslate">arrow_forward</i>
    </router-link>

    <ui-toolbar class="nav" data-test-id="main-nav" :raised="false">
      <div slot="icon"></div>
      <div class="nav-merchant">
        <img :src="merchant.logo.path">
        <div class="info">
          <span>{{ merchant.nameTrimmed }}</span>
          <small>{{ merchant.subtitleTrimmed }}</small>
        </div>
      </div>
      <div slot="actions">
        <app-nav-cart-button></app-nav-cart-button>
      </div>
    </ui-toolbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavCartButton from './NavCartButton'

export default {
  components: {
    'app-nav-cart-button': NavCartButton
  },
  computed: {
    ...mapGetters(['merchant', 'user'])
  }
}
</script>
