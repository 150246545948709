<template>
  <div>
    <div class="category-nav" :class="{'category-nav-showed': isShowed || isListOpen, 'category-nav-menu-open': isListOpen}">

      <router-link :to="{name: 'checkout', params: {orderToken: user.currentOrder.token, merchantSlug: user.currentOrder.merchantSlug}}"
                   class="merchant-current-order-fixed"
                   v-if="user && user.order">

        <span>{{ $t('go_on_current_order') }}</span>
        <i class="material-icons notranslate">arrow_forward</i>
      </router-link>

      <div class="category-nav-nav" :class="{'category-nav-nav-order' : user && user.order}">
        <div class="info" @click="isListOpen = true">
          <transition name="slide-fade" mode="out-in">
            <span class="category-title" v-if="current" :key="current.name"><span>{{ current.name }}</span><i class="material-icons notranslate">expand_more</i></span>
          </transition>
        </div>
        <div class="action">
          <app-nav-cart-button></app-nav-cart-button>
        </div>
      </div>


      <div class="menu-list">
        <div class="menu-head">
          <strong>{{$t('category_nav_menu')}}</strong>
          <ui-icon-button
              color="black"
              icon="close"
              size="large"
              type="secondary"
              @click="isListOpen = false"
          ></ui-icon-button>
        </div>
        <div class="menu-body">
          <a href="#"
             :class="{'active': current && current.id === category.id}"
             v-for="category in menu"
             v-if="!(category.availability && category.availability.available === false && cart.type === 'on_site_counter')"
             @click.prevent="navigate(category.id)"
          >{{category.title}}</a>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div class="menu-list-overlay" v-if="isListOpen" @click="isListOpen = false"></div>
    </transition>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import animateScrollTo from 'animated-scroll-to';
import NavCartButton from './NavCartButton'

export default {
  components: {
    'app-nav-cart-button': NavCartButton
  },
  data(){
    return {
      isShowed: false,
      isListOpen: false,
      elements: null,
      current: null
    }
  },
  methods: {
    check(){

      this.isShowed = document.querySelector('.app-page').scrollTop > 30;
      let current = this.elements.find(category => category.offset + category.elementHeight > document.querySelector('.app-page').scrollTop);
      if(current){
        this.current = current;
      }
    },
    getElementsOffset(){
      this.elements = [];
      this.menu.forEach((category) => {
        let element = document.getElementById('category'+category.id).getBoundingClientRect();
        let body = document.querySelector('.app-page').getBoundingClientRect();
        let offset = (element.top - body.top) - 70 - 40;
        this.elements.push({id: category.id, offset: offset, elementHeight: element.height, name: category.title});
      });

      this.current = this.elements[0];
    },
    navigate(id){
      this.isListOpen = false;
      let element = this.elements.find(category => category.id === id);
      animateScrollTo(element.offset, {elementToScroll: document.querySelector('.app-page')})
    }
  },
  computed: {
    ...mapGetters(['menu', 'user', 'cart'])
  },
  mounted() {
    this.getElementsOffset();
    this.check();

    document.querySelector('.app-page').addEventListener('scroll', (e) => {
      this.check();
    })
  }
}
</script>
