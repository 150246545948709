<template>
  <div class="card-meta" v-if="meta">
    <div class="icon">
      <i class="material-icons notranslate" v-if="meta.type === 'cutlery'">restaurant</i>
      <i class="material-icons notranslate" v-else-if="meta.type === 'chopsticks'">set_meal</i>
      <i class="material-icons notranslate" v-else>add_circle_outline</i>
    </div>
    <div class="info">
      <span>{{ $t('cart_meta_unit_add_'+meta.type) }}</span>
      <small v-if="meta.priceWt.value > 0">{{meta.priceWt.price}}<span v-if="meta.unitPrice">{{$t('per_cutlery')}}</span> </small>
    </div>
    <div class="quantity" v-if="meta.unitPrice">
      <button @click="updateQuantity(meta, -1)">-</button>
      <span class="number">{{meta.selectedQuantity}}</span>
      <button @click="updateQuantity(meta, 1)">+</button>
    </div>
    <ui-checkbox v-else @change="updateSelect(meta)" v-model="meta.selected"></ui-checkbox>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
export default {
  props: ['meta'],
  data() {
    return {
      timeout: false
    }
  },
  methods: {
    ...mapActions(['updateMeta']),
    updateSelect(meta){
      this.updateMeta({meta:meta});
    },
    updateQuantity(meta, quantity){
      clearTimeout(this.timeout);
      meta.selectedQuantity = meta.selectedQuantity + quantity;
      if(meta.selectedQuantity < 0){
        meta.selectedQuantity = 0;
      }

      this.timeout = setTimeout(() => {
        this.updateMeta({meta:meta});
      }, 800);
    }
  },
  computed: {
    ...mapGetters(['cart'])
  }
}
</script>
