<template>
  <app-panel type="panel"
             transition="fade"
             event-name="openExclusiveProactive"
             close-event-name="closeExclusiveProactive"
             hide-validate-button
             class="exclusive-product-modal"
             @open="open"
             size="medium"
             hide-nav>
    <div class="exclusive-container" v-if="category && otherCategory">
      <h2>{{$t('exclusive_proactive')}}</h2>
      <p v-if="otherProducts === 1">{{$t('exclusive_proactive_p', {category: otherCategory.title, date: otherCategory.availability.availableDateHuman})}}</p>
      <p v-if="otherProducts > 1">{{$t('exclusive_proactive_plurial_p', {category: otherCategory.title, date: otherCategory.availability.availableDateHuman})}}</p>

      <div class="exclusive__action">
        <ui-button color="light" @click="close">Annuler</ui-button>
        <ui-button color="primary" :loading="isLoading" @click="deleteExclusiveProduct">{{$t('exclusive_delete')}}</ui-button>
      </div>
    </div>

  </app-panel>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import PanelTemplate from "@/mobileComponents/PanelTemplate";
export default {
  components: {'app-panel': PanelTemplate},
  data() {
    return {
      category: null,
      product: null,
      quantity: null,
      selectedOptions: null,
      comment: null,
      isLoading: false,
      changeDate: null,
      otherProducts: 0,
      otherCategory: false
    }
  },
  methods: {
    ...mapActions(['clearExclusiveCart', 'addToCart']),
    open(data){
      this.category = data.category;
      this.selectedOptions = data.selectedOptions;
      this.quantity = data.quantity;
      this.comment = data.comment;
      this.changeDate = data.changeDate;
      this.product = data.product;

      this.otherProducts = 0;
      let exclusiveCategoryId = null;
      this.cart.OrderProducts.forEach((p) => {
        if(p.categoryExclusive === true){
          this.otherProducts += parseInt(p.quantity);
          exclusiveCategoryId = p.categoryId;
        }
      });

      this.otherCategory = this.menu.find(e => e.id === exclusiveCategoryId);

    },
    close(){
      this.$root.$emit('closeProduct');
      this.$root.$emit('closeExclusiveProactive');
    },
    async deleteExclusiveProduct(){
      let exclusive = this.cart.OrderProducts.find(e => e.categoryExclusive === true);

      await this.clearExclusiveCart(exclusive.categoryId);
      this.isLoading = true;
      this.addToCart({options: this.selectedOptions, productId: this.product.id, quantity: this.quantity, comment: this.comment}).then(() => {
        this.isLoading = false;
        this.$root.$emit('addToCart', {product:this.product, quantity:this.quantity});
        this.close();
      });
    }
  },
  computed: {
    ...mapGetters(['menu', 'cart'])
  },
  mounted() {

  }
}
</script>
