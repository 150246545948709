<template>
  <app-panel type="panel"
             class="address-editor-modal"
             event-name="openAddressEditor"
             close-event-name="closeAddressEditor"
             :title="$t('address_delivery_details')"
             :validate-button="$t('save_address')"
             @validated="saveAddress"
             @open="open">

    <div class="panel-body">
      <div class="editor-body" v-if="address">
        <div class="row no-gutters">
          <div class="col-12">
            <div class="address-title">
              <h6>{{$t('your_addresses')}}</h6>
            </div>
          </div>
          <div class="col-12">
            <input type="text" class="form-control" disabled :value="address.address" :placeholder="$t('set_your_address')">
          </div>
          <div class="col-12">
            <input type="text" class="form-control" data-test-id="address_editor_address2" v-model="address.address2" :placeholder="$t('set_your_address2')">
          </div>
          <div class="col-5">
            <div class="pr-2">
              <input type="text" disabled class="form-control" :value="address.zip" :placeholder="$t('set_your_zip')">
            </div>
          </div>
          <div class="col-7">
            <input type="text" disabled class="form-control" :value="address.city" :placeholder="$t('set_your_city')">
          </div>
          <div class="col-12 mt-3">
            <div class="address-title">
              <h6>{{$t('more_info')}}</h6>
            </div>
          </div>
          <div class="col-12">
            <input type="text" class="form-control" data-test-id="address_editor_name" v-model="address.name" :placeholder="$t('set_your_address_name')">
          </div>
          <div class="col-12">
            <input type="text" class="form-control" data-test-id="address_editor_house_name" v-model="address.houseName" :placeholder="$t('set_your_house_name')">
          </div>
          <div class="col-12">
            <input type="text" class="form-control" data-test-id="address_editor_house_info" v-model="address.houseInfo" :placeholder="$t('set_your_house_info')">
          </div>
          <div class="col-12">
            <textarea class="form-control" v-model="address.comment" data-test-id="address_editor_comment" :placeholder="$t('set_your_address_comment')">{{address.comment}}</textarea>
          </div>
        </div>
      </div>
      <div class="panel-bottom"></div>
    </div>
  </app-panel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import PanelTemplate from "@/mobileComponents/PanelTemplate";

export default {
  components: {
    'vue-google-autocomplete': VueGoogleAutocomplete,
    'app-panel': PanelTemplate
  },
  data(){
    return {
      address: null,
    }
  },
  methods: {
    ...mapActions(['updateAddress', 'setOrderAddress']),
    open(address){
      this.address = address;
    },
    saveAddress(){
      this.updateAddress(this.address)
          .then((address) => {
            if(!this.user){
              this.setOrderAddress(address.id);
            }
          });
      this.$root.$emit('closeAddressEditor');
    }
  },
  computed: {
    ...mapGetters(['cart', 'user'])
  }
}
</script>
