<template>
  <section class="product-section-mono-cat" v-if="productCount > 0 && dataHighlight.length > 0" :id="'category' + data.id">
    <div class="specialties section-title">
      <span>Nos Spécialités</span>
    </div>

    <swiper
      :slides-per-view="'auto'"
      :space-between="30"
      :slides-offset-before="20"
      :slides-offset-after="20"
      class="product-specialty"
    >
      <swiper-slide
        v-for="product in dataHighlight"
        :arial-id="product.id"
        :key="product.id"
        v-if="cart.type === 'on_site' && product.onsite === true || cart.type === 'pickup' && product.pickup === true || cart.type === 'on_site_counter' && product.onsite === true || cart.type === 'delivery' && product.delivery === true"
        style="width: 200px; position: relative; height: auto;"
      >
        <div
          class="product-item"
          :class="{'product-item-disabled': !product.avaible}"
          @click="openProduct(product)"
        >
          <div class="star">
            <i class="material-icons">star</i>
            <span>Produit star</span>
          </div>
          <img
            :src="product.imageLarge.path ? product.imageLarge.path : merchant.logo.path"
            :style="!product.imageLarge.path ? 'filter: brightness(70%);' : ''"
          >
          <div class="infos">
            <div class="card_title">
              <strong class="name">{{ product.name }}</strong>
              <p>{{ product.descriptionShort }}</p>
            </div>
            <div class="card_footer">
              <span class="price" v-if="cart.type === 'on_site' || cart.type === 'on_site_counter'">{{ product.priceOnsite.price }}</span>
              <span class="price" v-if="cart.type === 'pickup'">{{ product.pricePickup.price }}</span>
              <span class="price" v-if="cart.type === 'delivery'">{{ product.priceDelivery.price }}</span>
              <small v-if="!product.avaible">Indisponible</small>
              <i v-if="product.avaible" class="material-icons">arrow_forward</i>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>

  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import {$vfm} from "vue-final-modal";
import AddProduct from "@/components/Modals/Product/AddProduct";

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper.scss'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['menu'],
  data(){
    return {
      data: false,
      dataHighlight: []
    }
  },
  methods: {
    openProduct(product){
      if(this.merchant.activated === false){
        this.$root.$emit('openOrderTime', {initiated: false});
        return;
      }
      $vfm.show({component: AddProduct, bind: {product: JSON.parse(JSON.stringify(product))}});
      window.history.pushState({}, "",location.pathname + '#product');
    },
  },
  computed: {
    ...mapGetters(['cart', 'merchant']),
    productCount(){
      if(!this.data){
        return 0;
      }
      if(this.cart.type === 'delivery'){
        return this.data.filter(p => p.delivery === true).length;
      }
      else if(this.cart.type === 'pickup'){
        return this.data.filter(p => p.pickup === true).length;
      }
      else if(this.cart.type === 'on_site'){
        return this.data.filter(p => p.onsite === true).length;
      }
      else if(this.cart.type === 'on_site_counter'){
        return this.data.filter(p => p.onsite === true).length;
      }
    }
  },
  mounted() {
    this.data = JSON.parse(JSON.stringify(this.menu))

    this.dataHighlight = this.data.reduce((acc, category) => {
      return acc.concat(category.products.filter(product => product.highlighted))
    }, [])

    this.data = this.data.reduce((acc, category) => {
      return acc.concat(category.products)
    }, [])
  }
}
</script>

<style lang="scss">
.product-specialty
{
  margin-top: 20px;
  margin-bottom: 40px;
  .product-item
  {
    height: 100%;
    border: solid 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .star
    {
      z-index: 1;
      background-color: #ffc400;
      position: absolute;
      border-radius: 8px;
      color: white;
      margin: 0.8em 0 0 0.8em;
      padding: 3px 8px 3px 8px;
      font-size: 0.9em;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      i
      {
        font-size: 1rem;
        margin-right: 0.2em;
      }
    }
    img
    {
      border-bottom: solid 1px rgba(0, 0, 0, 0.08);
    }
    .infos
    {
      padding: 0.8em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .card_title
      {
        .name
        {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        p
        {
          color: #666666;
          margin-top: 0.2em;
          margin-bottom: 1em;
          font-size: 0.8em;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .card_footer
      {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        small
        {
          background-color: rgb(228, 228, 228);
          border-radius: 6px;
          padding: 0.4em 0.6em;
        }
        i
        {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px;
          font-size: 17px;
          height: 25px;
          width: 25px;
          background-color: #2196F3;
          border-radius: 6px;
          color: white;
        }
        small
        {
          text-transform: uppercase;
          line-height: normal;
        }
      }
    }
  }
  .product-item-disabled
  {
    opacity: 0.4;
  }
}
</style>
