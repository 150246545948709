<template>
  <app-page>
    <div class="loading-screen" v-if="isLoading">
      <loader></loader>
    </div>
    <div v-if="!isLoading && !merchantError" class="merchant-page">
      <app-nav></app-nav>
      <app-category-nav></app-category-nav>
      <app-header></app-header>

      <app-order-config></app-order-config>
      <app-specialties :menu="menu"></app-specialties>
      <app-product-carousel></app-product-carousel>


      <div class="pb-3" v-if="!cart.deliveryAvailable && cart.deliveryClosedReason && merchant.shifts.open">
        <app-delivery-unavailable-reason></app-delivery-unavailable-reason>
      </div>
      <app-announcement position="top_product" class="mb-2"></app-announcement>


      <app-product-section v-for="category in menu" v-bind:key="category.id" :category="category"></app-product-section>
      <app-footer></app-footer>
      <app-cart-floating-button></app-cart-floating-button>

      <app-map></app-map>
      <app-cart></app-cart>
      <app-hours></app-hours>
      <app-delivery-info></app-delivery-info>
      <app-platform-fee-info></app-platform-fee-info>
      <app-cart-comment></app-cart-comment>
      <app-auth></app-auth>
      <app-login></app-login>
      <app-register></app-register>
      <app-no-account></app-no-account>
      <app-addresses></app-addresses>
      <app-addresse-editor></app-addresse-editor>
      <app-onboarding-account></app-onboarding-account>
      <app-order-time></app-order-time>
      <app-product-exclusive-reactive></app-product-exclusive-reactive>
      <app-product-exclusive-proactive></app-product-exclusive-proactive>
      <app-product-change-date></app-product-change-date>
      <app-three-d-secure></app-three-d-secure>
    </div>

  </app-page>
</template>

<script>
import Nav from '../mobileComponents/Merchant/Nav'
import Header from '../mobileComponents/Merchant/Header'
import OrderConfig from '../mobileComponents/Merchant/OrderConfig'
import ProductCarousel from '../mobileComponents/Products/ProductCarousel'
import ProductSection from '../mobileComponents/Products/ProductSection'
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import CategoryNav from "@/mobileComponents/Merchant/CategoryNav";
import Cart from "@/mobileComponents/Merchant/Cart";
import CartFloatingButton from "@/mobileComponents/Merchant/CartFloatingButton";
import Auth from "@/mobileComponents/User/Auth";
import OnboardingAccount from "@/mobileComponents/User/OnboardingAccount";
import Login from "@/mobileComponents/User/Login";
import Addresses from "@/mobileComponents/Merchant/Addresses";
import AddressEditor from "@/mobileComponents/Merchant/AddressEditor";
import CartComment from "@/mobileComponents/Merchant/CartComment";
import Map from "@/mobileComponents/Merchant/Map";
import DeliveryInfo from "@/mobileComponents/Merchant/DeliveryInfo";
import PlatformFeeInfo from "@/mobileComponents/Merchant/PlatformFeeInfo";
import Hours from "@/mobileComponents/Merchant/Hours";
import Register from "@/mobileComponents/User/Register";
import '../scss/app_mobile.scss';
import Footer from "@/mobileComponents/Merchant/Footer";
import OrderTime from "@/mobileComponents/Merchant/OrderTime";
import ExclusiveReactive from "@/mobileComponents/Products/ExclusiveReactive";
import ExclusiveProactive from "@/mobileComponents/Products/ExclusiveProactive";
import ChangeDateCart from "@/mobileComponents/Products/ChangeDateCart";
import Announcement from "@/mobileComponents/Merchant/Announcement";
import NoAccount from "@/mobileComponents/User/NoAccount";
import ThreeDSecure from "@/mobileComponents/Merchant/ThreeDSecure"
import DeliveryUnavailableReason from "@/mobileComponents/Merchant/DeliveryUnavailableReason";
import Loader from "@/components/Loader";
import Specialties from '../mobileComponents/Products/Specialties'

export default {
  components: {
    'loader': Loader,
    'app-nav': Nav,
    'app-auth': Auth,
    'app-login': Login,
    'app-addresses': Addresses,
    'app-addresse-editor': AddressEditor,
    'app-onboarding-account': OnboardingAccount,
    'app-cart': Cart,
    'app-three-d-secure': ThreeDSecure,
    'app-category-nav': CategoryNav,
    'app-header': Header,
    'app-order-config': OrderConfig,
    'app-product-carousel': ProductCarousel,
    'app-product-section': ProductSection,
    'app-cart-floating-button': CartFloatingButton,
    'app-cart-comment': CartComment,
    'app-map': Map,
    'app-delivery-info': DeliveryInfo,
    'app-platform-fee-info': PlatformFeeInfo,
    'app-hours': Hours,
    'app-register': Register,
    'app-footer': Footer,
    'app-order-time': OrderTime,
    'app-product-exclusive-reactive': ExclusiveReactive,
    'app-product-exclusive-proactive': ExclusiveProactive,
    'app-product-change-date': ChangeDateCart,
    'app-announcement': Announcement,
    'app-no-account': NoAccount,
    'app-delivery-unavailable-reason': DeliveryUnavailableReason,
    'app-specialties': Specialties,
  },
  metaInfo(){
    let title = !this.isLoading && this.merchant.metadata ? this.merchant.metadata.title : 'Delicity | Nouvelle plateforme de Commandes & Livraison à Nice';
    let description = !this.isLoading && this.merchant.metadata ? this.merchant.metadata.description : 'Faites des économies | Delicity ne coûte que 1€ | Livreurs & les Restaurateurs mieux Rémunérés ! Commandez dès Maintenant';
    let image = !this.isLoading && this.merchant.metadata && this.merchant.metadata.image ? this.merchant.metadata.image.path : 'https://delicity.b-cdn.net/public/assets/rsslogo.jpg';

    if(!this.isLoading){
      return {
        title: title,
        meta: [
          { name: 'description', content: description },
          { property: 'og:title', content: title },
          { property: 'og:description', content: description },
          { property: 'og:image', content: image },
          { property: 'og:url', content: location.href },
          { property: 'twitter:url', content: location.href },
          { property: 'twitter:title', content: title },
          { property: 'twitter:description', content: description },
          { property: 'twitter:image', content: image },
        ]
      }
    }
  },
  methods:{
    ...mapActions(['getMerchant', 'sendStat', 'createOrderToken', 'loadOrder', 'getAccessToken', 'getUser', 'checkLogin', 'getNearestAddress', 'setOrderType', 'backButton', 'setOrderTracking', 'setOrderDiscountCode']),
    updateFavicon(){
      if(this.merchant && this.merchant.metadata){
        document.getElementById('ico').href = this.merchant.metadata.icon;
        document.getElementById('i180').href = this.merchant.metadata.icon180x180;
        document.getElementById('i32').href = this.merchant.metadata.icon32x32;
        document.getElementById('i16').href = this.merchant.metadata.icon16x16;
      }
    }
  },
  computed: {
    ...mapGetters(['menu', 'merchant', 'userToken', 'user', 'cart', 'merchantError', 'modal', 'products']),
    isLoading(){
      return !(this.menu !== null && this.merchant !== null && this.user !== null && this.cart !== null);
    }
  },
  async mounted() {
    localStorage.setItem('last_page', 'merchant');
    window.scrollTo(0, 0);
    this.checkLogin();

    const slug = this.$route.params.merchantSlug ? this.$route.params.merchantSlug : location.host;

    await this.getMerchant({slug: slug, type: this.$route.query.type});
    this.updateFavicon();

    this.getUser();
    let source = 'direct';
    if(this.$route.query.source === 'marketplace'){
      source = 'marketplace';
    }
    this.loadOrder({slug: this.merchant.slug, type: this.$route.query.type, source})
        .catch(() => {
          console.log('> Cart error with slug "'+slug+'", reload in 3s')
          setTimeout(() => {
            location.reload();
          },3000)
        });

    if(this.$route.query.product_id){
      const product = this.products.find(e => parseInt(e.id) === parseInt(this.$route.query.product_id));
      if(product){
        this.$root.$emit('openProduct', {product: product});
      }
    }

    setTimeout(() => {
      if(this.$route.query.cart){
        this.$root.$emit('openCart');
      }
      if(this.$route.query.stripe_error){
        this.$root.$emit('openCart');
      }
      if(this.$route.query.t){
        this.setOrderTracking(this.$route.query.t);
      }
      if(this.$route.query.openCart){
        this.$root.$emit('openCart');
      }
      if(this.$route.query.promo_code){
        this.setOrderDiscountCode({code:this.$route.query.promo_code, force: true});
      }
      if(this.$route.query.ref && this.$route.query.ref === 'merchant_on_site'){ // Set onsite
        this.setOrderType('on_site');
      }
      let hash = this.$route.hash.replace('#', '');
      if(hash && (hash !== 'openProduct' && hash !== 'openProductEditor' && hash !== 'openExclusiveProactive' && hash !== 'openExclusiveReactive' && hash !== 'openChangeDateCart')){
        this.$root.$emit(hash);
      }
      this.getNearestAddress();
    }, 500)


    this.$router.beforeEach((to, from, next) => {
      this.backButton({from:from.hash.replace('#', ''), to:to.hash.replace('#', '')});
      next();
    });

    this.sendStat({name: 'mobile-merchant-page'});
  },
  watch: {
    userToken(){
      if(this.$route.query.authed){
        this.getAccessToken();
      }
    },
    merchantError(val){
      if(val){
        this.$router.push('/');
      }
    },
    modal(val){
      if(val.length === 0){
        document.querySelector('.app-page').style.overflowY = "auto";
        document.querySelector('.app-page').style.position = "fixed";
      }
      else {
        document.querySelector('.app-page').style.overflowY = "hidden";
        document.querySelector('.app-page').style.position = "fixed";
      }
    }
  }
}
</script>
