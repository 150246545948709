<template>
  <app-panel type="panel" ref="commentPanel" event-name="openCartComment" class="cart-comment" :title="$t('cart_comment_title')" :validate-button="$t('cart_comment_validate')" @validated="save">
    <textarea maxlength="250" :placeholder="$t('cart_comment_placeholder')" class="form-control" v-model="comment"></textarea>
    <div class="text-right p-1">
      <span class="text-muted" v-if="comment">{{ comment.length }}/250</span>
    </div>
  </app-panel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PanelTemplate from "@/mobileComponents/PanelTemplate";

export default {
  data(){
    return {
      comment: null
    }
  },
  components: {
    'app-panel': PanelTemplate
  },
  methods: {
    ...mapActions(['setOrderComment']),
    save(){
      this.setOrderComment(this.comment)
          .then(() => {
            this.$refs.commentPanel.close();
          });
    }
  },
  mounted() {
    this.comment = this.cart.comment;
  },
  computed: {
    ...mapGetters(['cart'])
  }
}
</script>
